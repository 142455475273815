import axios from "axios";
import store from "@/store";
import router from "@/router";
import {Buffer} from "buffer";

class ConfiguracionLogic {
    static instance = null;
    static createInstance() {
        var object = new ConfiguracionLogic();
        return object;
    }

    static getInstance() {
        if (!ConfiguracionLogic.instance) {
            ConfiguracionLogic.instance = ConfiguracionLogic.createInstance();
        }
        return ConfiguracionLogic.instance;
    }

    constructor() {
    }

    async detalle(){
        try {
            // Dynamic Imports
            var {default: HTTPHelper} = await import('@/helpers/http.helper');
            var {default: CommonHelper} = await import('@/helpers/common.helper');

            var ENV_APIPATH = process.env.APIPATH;
            var ENV_APIDEBUG = CommonHelper.parseBool(process.env.APIDEBUG);

            var currentRoute = router.currentRoute;
            var API_ACCESSTYPE = currentRoute.meta.apiAccessType;

            var urlRequest = ENV_APIPATH+"/configuracion/backend/detalle";

            var objRequest = {}

            var httpResponse = await HTTPHelper.httpPOST(urlRequest,objRequest,API_ACCESSTYPE,ENV_APIDEBUG);

            return httpResponse;

        } catch (error) {
            throw Error("[ConfiguracionLogic] detalle()::ERROR >>> "+error);
        }
    }

    async editar(objConfiguracion){
        try {
            // Dynamic Imports
            var {default: HTTPHelper} = await import('@/helpers/http.helper');
            var {default: CommonHelper} = await import('@/helpers/common.helper');

            var ENV_APIPATH = process.env.APIPATH;
            var ENV_APIDEBUG = CommonHelper.parseBool(process.env.APIDEBUG);

            var currentRoute = router.currentRoute;
            var API_ACCESSTYPE = currentRoute.meta.apiAccessType;

            var urlRequest = ENV_APIPATH+"/configuracion/backend/editar";
            var httpResponse = await HTTPHelper.httpPOST(urlRequest,objConfiguracion,API_ACCESSTYPE,ENV_APIDEBUG);

            return httpResponse;

        } catch (error) {
            throw Error("[ConfiguracionLogic] editar()::ERROR >>> "+error);
        }
    }

    async limpiarCache(){
        try {
            // Dynamic Imports
            var {default: HTTPHelper} = await import('@/helpers/http.helper');
            var {default: CommonHelper} = await import('@/helpers/common.helper');

            var ENV_APIPATH = process.env.APIPATH;
            var ENV_APIDEBUG = CommonHelper.parseBool(process.env.APIDEBUG);

            var currentRoute = router.currentRoute;
            var API_ACCESSTYPE = currentRoute.meta.apiAccessType;

            var objConfiguracion = {};

            var urlRequest = ENV_APIPATH+"/configuracion/backend/limpiarcache";
            var httpResponse = await HTTPHelper.httpPOST(urlRequest,objConfiguracion,API_ACCESSTYPE,ENV_APIDEBUG);

            return httpResponse;

        } catch (error) {
            throw Error("[ConfiguracionLogic] limpiarCache()::ERROR >>> "+error);
        }
    }

    async reiniciarQueue(){
        try {
            // Dynamic Imports
            var {default: HTTPHelper} = await import('@/helpers/http.helper');
            var {default: CommonHelper} = await import('@/helpers/common.helper');

            var ENV_APIPATH = process.env.APIPATH;
            var ENV_APIDEBUG = CommonHelper.parseBool(process.env.APIDEBUG);

            var currentRoute = router.currentRoute;
            var API_ACCESSTYPE = currentRoute.meta.apiAccessType;

            var objConfiguracion = {};

            var urlRequest = ENV_APIPATH+"/configuracion/backend/reiniciarqueue";
            var httpResponse = await HTTPHelper.httpPOST(urlRequest,objConfiguracion,API_ACCESSTYPE,ENV_APIDEBUG);

            return httpResponse;

        } catch (error) {
            throw Error("[ConfiguracionLogic] reiniciarQueue()::ERROR >>> "+error);
        }
    }


}

const instance = ConfiguracionLogic.getInstance();
export default instance;